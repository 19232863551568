import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

import Fact from "../sections/landing1/Fact";
import Cta1 from "../sections/landing1/Cta1";
import banner from "../assets/image/inner/banner1.png";
import Section from "../sections/landing4/aboutf1";
import Section1 from "../sections/landing4/aboutf2";
import Section2 from "../sections/landing4/aboutf3";

const AboutPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <div>
          <div className="position-relative">
            <div className="position-absolute pt-30 px-md-12 mb-11 mb-lg-14">
              <h2 className="gr-text-4 pt-25 mb-5 mb-lg-5 text-white">
                About Us
              </h2>
              <p className="gr-text-8 mb-10 text-white">
                We help companies with their engineering needs in software
                development and help adopt cloud native technologies to make
                their infrastructure robust and scalable.
              </p>
            </div>
          </div>
          <div className="banner-fluid-image">
            <img src={banner} alt="" className="w-100" />
          </div>
        </div>
        <div className="cta-section">
          {/* <Container>
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-20 bg-default-2">
              <div className="pt-30 px-md-12 text-center mb-11 mb-lg-14">
                <h2 className="title gr-text-2 mb-9 mb-lg-12 heading-color">
                  Our story
                </h2>
                <p className="gr-text-8 mb-10 text-color-opacity">
                  We help companies with their engineering needs in software
                  development and help adopt cloud native technologies to make
                  their infrastructure robust and scalable.
                </p>
              </div>
            </Row>
          </Container> */}
          <Container>
          <Section/>
          <Section1/>
          <Section2/>
          


        
          </Container>
          
          
        </div>

        {/* <div className="about-content pt-lg-15 pt-13 pb-13 pb-lg-25">
          <Container>
            <Row>
              <Col lg="6" className="mb-7 mb-lg-0">
                <div className="pe-xl-13">
                  <h2 className="gr-text-3 heading-color mb-0">
                    We are here to help the customers to get their success.
                  </h2>
                </div>
              </Col>
              <Col lg="6">
                <div className="pe-xl-15">
                  <p className="gr-text-8 text-color-opacity mb-7 mb-lg-10">
                    We have built 90+ products in a diverse range of domains
                    from enterprise SaaS to consumer-based web applications.
                  </p>
                  <p className="gr-text-8 text-color-opacity mb-0">
                    Our teams are honed by domain experts who help you make
                    pragmatic decisions in selecting the right infrastructure
                    and tools without losing sight of your product vision.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
        {/* <Content4 /> */}
        {/* <Fact /> */}
        {/* <Cta1 /> */}
      </PageWrapper>
    </>
  );
};
export default AboutPage;
