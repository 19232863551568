import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";

import imgC from "../../assets/image/l9/pic6.png";
import { Link } from "gatsby";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22">
        <Container>
          <Row className="align-items-center justify-content-center">
          <Col sm="10" lg="5" className="pe-lg-9">
              <div className="content-text">
                <h2 className="fw-light gr-text-5 mb-9 mb-lg-10 headings-color">
                Our Values
                </h2>
                <p className="gr-text-10 mb-0 text-black">
                 We aim to build a team of passionate technologists who value Trust, Exploration, Collaboration, and Agile decision-making. We welcome people from all walks of life to contribute to and strengthen our culture of empathy and inclusiveness.
                </p>
                {/*Link
                  to="/contact"
                  className="btn-link with-icon gr-text-blue gr-text-7 fw-bold mt-9"
                >
                  Talk to an Expert{" "}
                  <i className="icon icon-tail-right fw-bold"></i>
                </Link>*/}
              </div>
            </Col>
            <Col sm="10" lg="6" className="offset-lg-1 mt-10 mt-lg-0">
              <div
                className="content-img img-group-1"
                
              >
                <img className="img-fluid" src={imgC} alt="" />
              </div>
            </Col>
            
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
