import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";

import imgC from "../../assets/image/l9/pic8.png";
import { Link } from "gatsby";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22">
        <Container>
          <Row className="align-items-center justify-content-center">
          <Col sm="10" lg="5" className="pe-lg-9">
              <div className="content-text">
                <h2 className="fw-light gr-text-5 mb-9 mb-lg-10 headings-color">
                 Who We Are
                </h2>
                <p className="gr-text-10 mb-0 text-black">
                AIN is a woman owned small business that serves customers needs. With our passion and commitment we design reliable digital foundations to solve business problems. We work with clients as partners to achieve their technology solutions, build sustainable processes, that deliver people exceptional results. Deliver IT professional services in a secure manner.
                </p>
                {/*Link
                  to="/contact"
                  className="btn-link with-icon gr-text-blue gr-text-7 fw-bold mt-9"
                >
                  Talk to an Expert{" "}
                  <i className="icon icon-tail-right fw-bold"></i>
                </Link>*/}
              </div>
            </Col>
            <Col sm="10" lg="6" className="offset-lg-1 mt-10 mt-lg-0">
              <div
                className="content-img img-group-1"
                
              >
                <img className="img-fluid" src={imgC} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
